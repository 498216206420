<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <div>
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-form>
              <b-row>

                <!-- Field: fileData -->
                <b-col
                    cols="12"
                    md="4"
                >

                  <b-button
                      class="mt-2 col-12"
                      variant="warning"
                      @click="$refs.refInputEl2.click()"
                  >
                    <input
                        ref="refInputEl2"
                        type="file"
                        class="d-none"
                        @change="select"
                    >
                    <span class="">انتخاب ویدئو</span>
                  </b-button>

                </b-col>

                <b-col
                    cols="12"
                    md="4"
                >
                  <b-button
                      variant="primary"
                      class="mt-2 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      @click="upload"
                  >
                    ثبت
                  </b-button>
                </b-col>
              </b-row>
            </b-form>

            <b-progress
                v-if="uploaded !== 0 && uploaded !== 100"
                :value="uploaded"
                max="100"
                variant="primary"
                class=" mt-2"
            />

            <b-alert
                :show="uploaded === 100"
                variant="success"
                class="col-md-2 col-12"
            >
              <div class="alert-body mt-2">
                <feather-icon
                    class="mr-25"
                    icon="CheckIcon"
                />
                <span class="ml-25">فایل شما آپلود شده است.</span>
              </div>
            </b-alert>
            <b-alert
                :show="errorAlert"
                variant="danger"
                class="col-md-2 col-12"
            >
              <div class="alert-body mt-2">
                <feather-icon
                    class="mr-25"
                    icon="CloseIcon"
                />
                <span class="ml-25">آپلود فایل با مشکل مواجه شد!</span>
              </div>
            </b-alert>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import {
  BAlert, BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem, BForm, BFormGroup, BFormInput, BFormTextarea,
  BImg,
  BLink, BMedia, BOverlay,
  BRow, BTab, BTabs, BProgress, BModal
} from "bootstrap-vue";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from "vue-select";
import axios from "axios";
import {getUserData} from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {ProductGetProductsMediasForAdminRequest} from "@/libs/Api/Product";
import {MediaDeleteByPictureUrlRequest} from "@/libs/Api/Product";
import {MediaChangeMediaNameRequest, MediaChangePriorityRequest} from "@/libs/Api/Media";
import Helper from "@/libs/Helper";

export default {
  title: 'بارگذاری ویدئو - پنل ادمین مکس ',
  name: "PostAddMedia",
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
    BProgress,
    AppCollapse,
    AppCollapseItem,
    BModal,
  },
  data() {
    return {
      postId: this.$route.params.id,
      productType: this.$route.query.productType,
      userToken: getUserData().data.token,
      medias: null,
      errorAlert: false,
      overlay: false,
      deleteItem: null,
      baseURL: Helper.baseUrl,
      file: null,
      chunks: [],
      uploaded: 0,
      namePictureURL: null,
      priorityPictureURL: null,
      newName: '',
      newPriority: '',
    };
  },
  async created() {
    // await this.getProductMedias();
  },
  computed: {
    formData() {
      let formData = new FormData;

      if (this.file !== null) {
        formData.set('formFile', this.file, `${this.file.name}`);
      }
      return formData;
    },
    config() {
      return {
        method: 'POST',
        data: this.formData,
        url: 'https://api.maxterms.com/api/Post/UploadPostMedia?postId=' + this.postId ,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': this.userToken,
        },
        onUploadProgress: progressEvent => {
          this.uploaded = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }
      };
    },
  },
  methods: {
    upload() {
      axios(this.config).then(response => {

        this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `فایل با موفقیت آپلود شد.`,
          },
        })
        this.$router.push({name:'apps-post-list'})
      }).catch(error => {
        this.errorAlert = true;
        console.log(error);
      });
    },
    async getProductMedias() {
      let _this = this;
      _this.overlay = true

      let productGetProductsMediasRequest = new ProductGetProductsMediasForAdminRequest(_this);
      productGetProductsMediasRequest.setId(_this.postId)
      await productGetProductsMediasRequest.fetch(function (content) {
        _this.overlay = false;
        _this.medias = content;
      }, function (content) {
        console.log(content)
      });
    },
    showModal(param) {
      let _this = this;
      _this.deleteItem = param
    },
    showChangeNameModal(param1, param2) {
      let _this = this;
      _this.namePictureURL = param1;
      _this.newName = param2
    },
    showChangePriorityModal(param1) {
      let _this = this;
      _this.priorityPictureURL = param1;
      _this.newPriority = ''
    },
    select(event) {
      this.file = event.target.files.item(0);
      // this.createChunks();
    },
    async deleteMedia(param) {
      let _this = this;
      _this.overlay = true

      let mediaDeleteByPictureUrlRequest = new MediaDeleteByPictureUrlRequest(_this);
      mediaDeleteByPictureUrlRequest.setSocialMediaId(param);
      await mediaDeleteByPictureUrlRequest.fetch(function (content) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `حذف شد.`,
          },
        })
        window.location.reload();
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CloseIcon',
            variant: 'danger',
            text: `عملیات انجام نشد.`,
          },
        })
        console.log(error)
      })
    },
    async updateMediaName() {
      let _this = this;
      _this.overlay = true

      let data = {
        socialMediaId: _this.namePictureURL,
        newName: _this.newName
      }

      let mediaChangeMediaNameRequest = new MediaChangeMediaNameRequest(_this);
      mediaChangeMediaNameRequest.setParams(data);
      await mediaChangeMediaNameRequest.fetch(function (content) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        window.location.reload();
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CloseIcon',
            variant: 'danger',
            text: `عملیات انجام نشد.`,
          },
        })
        console.log(error)
      })
    },
    async updateMediaPriority() {
      let _this = this;
      _this.overlay = true

      let data = {
        socialMediaId: _this.priorityPictureURL,
        priority: _this.newPriority
      }

      let mediaChangePriorityRequest = new MediaChangePriorityRequest(_this);
      mediaChangePriorityRequest.setParams(data);
      await mediaChangePriorityRequest.fetch(function (content) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        window.location.reload();
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CloseIcon',
            variant: 'danger',
            text: `عملیات انجام نشد.`,
          },
        })
        console.log(error)
      })
    },
  }
}
</script>

<style scoped>

</style>